export const BG_COVER = `https://ik.imagekit.io/zaanfa/gabrian-lielya/cover_KrxI2MVSi.jpg?updatedAt=1695972228713`;
export const BG_WELCOMING_SECTION = `https://ik.imagekit.io/zaanfa/gabrian-lielya/welcome_eoBjDXio9.jpg?updatedAt=1695972228771`;
export const BG_WISHLIST = `https://ik.imagekit.io/invitatoid/template-rainajanssen/bg-wishlist_2i8I7jb5h.jpg?updatedAt=1638541154280`;
export const BG_RSVP = `https://user-images.githubusercontent.com/10141928/146665436-20914399-2fc0-4b9e-b05e-0e961c4320e6.jpg`;
export const BG_LIVESTREAM = `https://ik.imagekit.io/invitatoid/template-rainajanssen/bg-pre-livestream_REXdy4RJy.jpg?updatedAt=1638541152599`;
export const BG_FOOTER = `https://user-images.githubusercontent.com/10141928/160853754-d5b769cd-701f-4fe1-ba72-e493bee8a122.jpg`;
export const BG_START_JOURNEY = `https://ik.imagekit.io/invitatoid/template-rainajanssen/bg-start-journey_DwkmI92EvqJ.jpg?updatedAt=1638541149699`;
export const BG_COUNTING = `https://user-images.githubusercontent.com/10141928/146292235-21d28e45-72b7-41e1-90ac-26a3605529e2.jpg`;
export const BG_LOVESTORY = `https://user-images.githubusercontent.com/10141928/146198254-9f2df09b-7cc7-4cd9-bdce-bd0563a23a77.jpg`;
export const BG_GIFTCARD = `https://user-images.githubusercontent.com/10141928/147398898-8b47c166-f479-48f9-a5b6-5d80ffdd9358.jpeg`;
export const BG_IG_FILTER = `https://ik.imagekit.io/zaanfa/gabrian-lielya/filter_wdqwlLNQ7.jpg?updatedAt=1696838532152`;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/zaanfa/gabrian-lielya/dekstop_vTAFwQNPF.jpg?updatedAt=1695972228780`;

export const IMG_COVER = `https://ik.imagekit.io/invitatoid/template-rainajanssen/cover_qAEf5Jbyv.jpg?updatedAt=1638541165051`;
export const IMG_LOGO = `https://ik.imagekit.io/zaanfa/gabrian-lielya/logo_OnJQNwZB4.png?updatedAt=1695972228046`;

export const IMG_MAN = `https://ik.imagekit.io/zaanfa/gabrian-lielya/man_Jj72nCjxF.jpg?updatedAt=1695972228656`;
export const IMG_GIRL = `https://ik.imagekit.io/zaanfa/gabrian-lielya/girl_8BMaWYcfB.jpg?updatedAt=1695972228721`;
export const IMG_LOVE_STORY = `https://user-images.githubusercontent.com/10141928/146289521-e97786dc-98a1-43d3-9b0f-e9353b168766.jpg`;
export const IMG_QR_COVER = IMG_LOVE_STORY;

export const IMG_PHOTO_1 = 'https://ik.imagekit.io/zaanfa/gabrian-lielya/g1_qkbHJf7-Ip.jpg?updatedAt=1695972228889';
export const IMG_PHOTO_2 = 'https://ik.imagekit.io/zaanfa/gabrian-lielya/g2_V_tDwAYiY.jpg?updatedAt=1695972228617';
export const IMG_PHOTO_CONTENT = 'https://ik.imagekit.io/zaanfa/gabrian-lielya/g3_bevq4sxYu.jpg?updatedAt=1695972228699';

export const IMG_AYAT = 'https://ik.imagekit.io/zaanfa/gabrian-lielya/footer_S-LmmshGK.jpg?updatedAt=1695972228689';

export const IMG_SLIDE_1 = `https://ik.imagekit.io/invitatoid/template-rainajanssen/slide_1_n8StaSLkS.jpg?updatedAt=1638541151789`;
export const IMG_SLIDE_2 = `https://ik.imagekit.io/invitatoid/template-rainajanssen/slide_2_11_dNMMPw.jpg?updatedAt=1638541152275`;
export const IMG_COVID = {
  id: `https://user-images.githubusercontent.com/103095433/241068547-20f04f29-1531-4bc2-a5e5-c7e3ce1c2805.png`,
  en: `https://user-images.githubusercontent.com/103095433/241068561-c7d17d12-c32f-44a1-834a-af73e655b0b6.jpg`,
};

export const LOGO_INVITATO = `https://ik.imagekit.io/invitatoid/global-assets/invitato_hs3nyqsuwy_.png?updatedAt=1638541184695`;
export const SONG_CDN_URL = `https://res.cloudinary.com/invitato/video/upload/v1640358887/template-song/LeeHi_-_ONLY_Lyrics_nzuosa.mp4`;

//Collaboration
export const LOGO_VENDOR_DALANG = `https://ik.imagekit.io/invitatoid/global-assets/logo-partner/partner-dalang_bp5JgCazz.png?ik-sdk-version=javascript-1.4.3&updatedAt=1651639908257`;
export const LOGO_VENDOR_PENTONE_WHITE = `https://ik.imagekit.io/invitatoid/global-assets/logo-partner/partner-pentone-white_4cMUJafwM.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654518552744`;
export const LOGO_VENDOR_PENTONE_BLACK = 'https://user-images.githubusercontent.com/103095433/193169607-562d8316-835d-4c15-a6b4-2b3c7cd22621.png';
export const LOGO_VENDOR_TIRTOWENING = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-tirtowening_2o6jmuIlN.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911787';
export const LOGO_VENDOR_SAMAWA = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-samawa_KniSfe28i.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911529';
export const LOGO_VENDOR_SADEWA = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-sadewa_6J3D8PmsJ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911185';
export const LOGO_VENDOR_PRAHASTA = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-prahasta-white_5L4WQTBlc.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910828';
export const LOGO_VENDOR_FLATTER = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-flatter_SvHO8RoVF.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910005';
export const LOGO_VENDOR_AKSARA = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-aksara-white_Ofc6Xkib-.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708909659';
export const LOGO_VENDOR_DREAMWORKS = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-dreamworks-white_FFlUUi8Dk.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910073';